import React, { Component } from "react";
import {bindActionCreators} from 'redux';
import * as app_actions from '../store/app/actions';
import "../css/AboutUs.css";
import {connect} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as ICONS from '@fortawesome/free-solid-svg-icons';

class AboutUs extends Component {
    constructor (props) {
        super(props);
        this.state = {}

    }

    render () {
        return (
            <React.Fragment>
            <div className={'aboutUsPage flex-100 padd10'}>
                <div className={'flex-100 width100 paddBottom10px'}>
                    <div className={"aboutus-background flex-100 width100"}></div>
                </div>
                <h3 className={"paddBottom10px"}>
                    אודות פי.סי.אס גלובל
                </h3>
                <p className={"paddBottom10px"}>
                    פי.סי.אס גלובל בע"מ מסדירה את שיטת התשלום בהמחאות באמצעות מערכות סליקה מתקדמות לניהול סיכוני אשראי בצ'קים.
                </p>
                <p className={"paddBottom10px"}>
                    לחברה יש מעל 1500 לקוחות עסקיים בפריסה ארצית, המבצעים סליקות צ'קים בכל מקום ובכל זמן נתון.
                </p>
                <p className={"paddBottom10px"}>
                    לאחרונה השקנו את שירות הצ'ק הבטוח לבעלי נכסים על מנת לאפשר לבעלי נכסים (למגורים ו/או מסחר) לקבל משוכרים צ'קים בביטחון.
                </p>
                <p>שירות הצ'ק הבטוח לבעלי נכסים מציע לך שני מסלולים</p>
                <ol>
                  <li>בדיקת היתכנות פרעון הצ'ק של הדייר בצורה דיגיטלית מיידית!</li>
                  <li>שירות הבטחת פרעון צ'קים וזיכוי על צ'ק חוזר תוך 7 ימי עסקים!</li>
                </ol>

                <div className="width100">
                    <a href="https://www.pcsglobal.co.il" target="_blank">
                        <FontAwesomeIcon style={{marginLeft:5}} icon={ICONS.faGlobeAsia}/>
                        בקרו אותנו באתר
                    </a>
                </div>
            </div>
            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);

