import React, { Component } from "react";
import {bindActionCreators} from 'redux';
import * as app_actions from '../store/app/actions';
import "../css/Login.css";
import Form from '../components/Form'
import {getLoginForm} from '../config';
import rest from '../rest';
import {connect} from "react-redux";
import $ from 'jquery';

class Login extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false
        }
        this.props.actions.updateFooter({
            goBack:false,
            edit:false,
            aboutUs:true,
            phone: true
        })
    }

    submit(fields){
        this.setState({loading: true});
        let toS = {
            Op:"Rental.Login",
            TZ:fields.id.value,
            Phone:fields.property_renter_phone.value,
            Password:fields.property_renter_password.value,
        }
        rest.login(toS)
            .then((data)=>{
                data.token = data.key;
                data.username = toS.TZ;
                return data;
            })
            .then(this.props.actions.login)
            .then(()=>{
                let { history } = this.props;
                history.replace({pathname: '/home'});
            })
            .catch((err)=>{
                this.props.actions.updateModal({
                    body: err.errormsg,
                    show: true
                });
            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }

    recoverPass() {
        let { history } = this.props;
        history.push({
            pathname: `/recover`,
            query: {
                id: $("#id").val(),
                property_renter_phone: $("#property_renter_phone").val()
            }
        });
    }

    goToRegister() {
        let { history } = this.props;
        history.replace({pathname: '/register'});
    }
    render () {
        return (
            <div className={'loginPage flex-100'}>
                <h3 className="loginTitle display-flex flex justify-content-center align-content-center width100 padd15px">
                    בדיקת צ'קים לשכירויות
                </h3>
                <Form fields={getLoginForm()} callback={this.submit.bind(this)} loading={this.state.loading}
                      forgetPass={true} history={this.props.history} hidePolicy={true} submitText={"התחבר"}
                />
                <div className="flex-100 width100 display-flex layout-align-center">
                    <a onClick={this.recoverPass.bind(this)}>
                        <span className={'color-a'}>שכחתי סיסמא</span>
                    </a>
                </div>

                <div onClick={this.goToRegister.bind(this)} className="gotoRegister display-flex layout-align-center-center justify-content-center layout-column">
                    <div className='display-flex width100 justify-content-center'>הצטרפות חינם</div>
                    <div className='display-flex width100 justify-content-center'>לבעלי נכסים</div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

