import React, { Component } from "react";
import "../css/Form.css";
import {FormErrors} from './FormErrors';
import { Form,Button,Spinner} from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import * as app_actions from "../store/app/actions";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import Privacy from '../components/Privacy';
import {banks} from '../config';
import moment from "moment";
import $ from 'jquery';

const actions = [
    app_actions,
]
let FIELDS = {}
let FIELDS_MIRROR = {};

class PCSForm extends Component {
    constructor (props) {
        super(props);
        FIELDS = this.props.fields;
        Object.keys(FIELDS).forEach(v=>{FIELDS_MIRROR[v]=v});
        this.state = {
            fields:cloneDeep(FIELDS),
            formErrors: {},
            validation: {},
        }
    }

    componentDidMount() {
        if (this.state.fields.id) {
            this.validateField(FIELDS_MIRROR.id, '');
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let validation = this.state.validation;
        let KEYS = FIELDS_MIRROR;
        switch(fieldName) {
            case KEYS.property_owner_email:
                let valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors[fieldName] = valid ? '' : 'אימייל לא תקין';
                validation[fieldName] = !!valid;
                break;
            case KEYS.property_purpose: {
                let valid = value;
                fieldValidationErrors[fieldName] = valid ? '' : 'בחר מטרת נכס';
                validation[fieldName] = !!valid
                break;
            }
            case KEYS.first_payment:
            case KEYS.total_payment: {
                // const first_payment = this.refs[KEYS.first_payment].value;
                break;
            }
            case KEYS.bank_id: {
                let valid = value;
                let bank = banks.find(value1 => value1.key == value);
                if (!bank) {
                    bank = banks.find(value1 => value1.key == 0+value);
                }
                if (!bank){
                    fieldValidationErrors[fieldName] = 'מספר בנק לא תקין';
                    validation[fieldName] = false
                } else {
                    this.refs[KEYS.bank_name].value = bank.value;
                    let fields = this.state.fields;
                    fields[KEYS.bank_name].value = bank.key;
                    this.setState({fields});
                    fieldValidationErrors[fieldName] = '';
                    validation[fieldName] = true;
                }
                break;
            }
            case KEYS.bank_name: {
                let valid = value;
                let bank = banks.find(value1 => value1.key==value);
                if (bank) {
                    this.refs[KEYS.bank_id].value = bank.key;
                    let fields = this.state.fields;
                    fields[KEYS.bank_id].value = bank.key;
                    validation[fieldName] = true;
                    fieldValidationErrors[fieldName] = '';
                    this.setState({fields});
                } else {
                    fieldValidationErrors[fieldName] = 'בנק לא תקין';
                    validation[fieldName] = false
                }
                break;
            }
            case KEYS.property_owner_id:
            case KEYS.property_renter_id:
            case KEYS.id: {
                let id = this.refs[fieldName].value;
                while(id.length<9){
                    id="0"+id;
                }
                let byteNumber = 1; let tempId=0;
                for (let i = 0; i < id.length; i += 1) {
                    let num = Number(id.charAt(i))*byteNumber;
                    num=num.toString();
                    let internalNum=0;
                    for (let j = 0; j < num.length; j += 1) {
                        internalNum+=Number(num.charAt(j))
                    }
                    tempId+=internalNum;
                    byteNumber=(byteNumber===1)?2:1;
                }

                let resetGuarantee = false;
                if (tempId%10===0){
                    fieldValidationErrors[fieldName] = '';
                    validation[fieldName] = true;
                    let hp = Number(id.charAt(0)+ id.charAt(1));
                    let hpArr=[51,52,55,58];
                    if (hpArr.indexOf(hp)!== -1){
                    } else {
                        resetGuarantee = true;
                    }
                } else {
                    fieldValidationErrors[fieldName] = 'ת״ז או ח״פ לא תקינים';
                    validation[fieldName] = false
                    resetGuarantee = true;
                }

                try {
                    if (resetGuarantee) {
                        if (this.refs[`parent_${KEYS.guarantee_id}`]){
                            this.refs[`parent_${KEYS.guarantee_id}`].style.display = 'none';
                            document.getElementById('guarantee_id').removeAttribute('required');

                        }
                        this.refs[KEYS.guarantee_id].value = '';
                        fieldValidationErrors[KEYS.guarantee_id] = '';
                        validation[KEYS.guarantee_id] = true
                    } else {
                        if (this.refs[`parent_${KEYS.guarantee_id}`]){
                            this.refs[`parent_${KEYS.guarantee_id}`].style.display = 'block';
                            document.getElementById('guarantee_id').setAttribute('required','true');
                        }
                    }
                } catch(err) {

                }
                break;
            }
            case KEYS.guarantee_id: {
                let id = this.refs[fieldName].value;
                while(id.length<9){
                    id="0"+id;
                }

                let hp = Number(id.charAt(0)+ id.charAt(1));
                let hpArr=[51,52,55,58];
                if (hpArr.indexOf(hp)!== -1){
                    fieldValidationErrors[fieldName] = 'ת״ז ערב לא תקינה';
                    validation[fieldName] = false;
                    break;
                }

                let byteNumber = 1; let tempId=0;
                for (let i = 0; i < id.length; i += 1) {
                    let num = Number(id.charAt(i))*byteNumber;
                    num=num.toString();
                    let internalNum=0;
                    for (let j = 0; j < num.length; j += 1) {
                        internalNum+=Number(num.charAt(j))
                    }
                    tempId+=internalNum;
                    byteNumber=(byteNumber===1)?2:1;
                }
                if (tempId%10===0){
                    fieldValidationErrors[fieldName] = '';
                    validation[fieldName] = true;
                } else {
                    fieldValidationErrors[fieldName] = 'ת״ז ערב לא תקינה';
                    validation[fieldName] = false;
                }


                break;
            }
            case KEYS.payment_number: {
                let new_fields = this.state.fields;
                let number = Number(this.refs[fieldName].value);
                if (!FIELDS[fieldName].children && !isNaN(number) || !number) {
                    return;
                }

                FIELDS[fieldName].children.reduce((previous, current, index, array)=>{
                    const parent = index ? index-1 : '';
                    const val = Number(this.refs[fieldName+parent].value) + 1;
                    new_fields[fieldName].children[index].value = val;
                    this.refs[fieldName+index].value = val;
                    return previous.value + 1;
                },number)

                this.setState({fields: new_fields})
                break;
            }
            default: {
                let valid = value;
                validation[fieldName] = !!valid
                break;
            }
        }
        this.setState({
            formErrors: fieldValidationErrors,
            validation: validation
        }, this.validateForm);
    }

    validateForm() {
        this.setState({formInvalid: Object.keys(this.state.validation).some(v=>!this.state.validation[v])});
    }

    getFormFields() {
        let fields = [];
        Object.keys(FIELDS).forEach((key)=>{
            let field = '';
            const selected = FIELDS[key].value || null;
            switch (FIELDS[key].type) {
                case 'select':{
                    field = <Form.Group ref={`parent_${key}`} key={key} controlId={key} className={'form-group'}>
                            <Form.Label>{FIELDS[key].title}</Form.Label>
                            <Form.Control ref={`${key}`} as="select" onChange={(event) => this.handleUserSelect(event)}
                                          {...FIELDS[key].settings || {}}  name={key} required >
                                {FIELDS[key].options.map((item)=>{
                                    return <option selected={selected==item.key} key={item.key} >{item.value}</option>
                                })}
                            </Form.Control>
                    </Form.Group>;
                    break;
                }
                case 'date':{
                    field = <Form.Group ref={`parent_${key}`} key={key} controlId={key}>
                        <Form.Label>{FIELDS[key].title}</Form.Label>
                        <Form.Control ref={`${key}`} type={FIELDS[key].type} placeholder={FIELDS[key].placeholder || ''}
                                      {...FIELDS[key].settings || {}} required
                                      name={key} onClick={(event) => this.toggleDate(event)}
                                      onChange={(event) => this.handleDateInput(event)}/>
                    </Form.Group>
                    break;
                }
                case 'textarea':{
                    field = <Form.Group ref={`parent_${key}`} key={key} controlId={key}>
                        <Form.Label>{FIELDS[key].title}</Form.Label>
                        <Form.Control ref={`${key}`} as={FIELDS[key].type} placeholder={FIELDS[key].placeholder || ''}
                                      {...FIELDS[key].settings || {}} required
                                      name={key} onChange={(event) => this.handleUserInput(event)}/>
                    </Form.Group>
                    break;
                }
                default: {
                    // className={this.state.validation[key]===false && 'invalidInputBorder'}
                    field = <Form.Group ref={`parent_${key}`} key={key} controlId={key}>
                        <Form.Label>
                            {FIELDS[key].title}&nbsp;
                            {FIELDS[key].subTitle && <Form.Text>{FIELDS[key].subTitle}</Form.Text>}
                        </Form.Label>
                        <Form.Control ref={`${key}`} type={FIELDS[key].type} placeholder={FIELDS[key].placeholder || ''}
                                      {...FIELDS[key].settings || {}} required defaultValue={selected}
                                      name={key} onBlur={(event) => this.handleUserInput(event)}
                                      onChange={(event) => this.handleUserInput(event)}/>

                        {FIELDS[key].children && <React.Fragment>
                            <p className='paddTop10px paddBottom10px'
                               data-toggle="collapse"
                               data-target="#collapseExample"
                               aria-expanded="false"
                               aria-controls="collapseExample"
                                >
                                <a className={'color-a'}>
                                    לחץ כאן במידה ומספרי הצ׳ק אינם בסדר רציף
                                </a>
                                </p>

                            <div className="collapse" id="collapseExample">
                                    <ol start={2}> {
                                        <React.Fragment>
                                            {FIELDS[key].children && FIELDS[key].children.map((o,i)=>{
                                                return (<li>
                                                    <Form.Control ref={`${key}${i}`} className='marginTop10px' type={o.type || FIELDS[key].type} placeholder={o.placeholder || FIELDS[key].placeholder || ''}
                                                                  onChange={(event) => this.childHandleUserInput(event,key,i)}
                                                                  {...FIELDS[key].settings || {}} required defaultValue={selected}
                                                                  name={key+i} />
                                                </li>)
                                            })}
                                        </React.Fragment>
                                    }
                                    </ol>
                            </div>
                        </React.Fragment>}
                        <p className={'invalidInput'}>{this.state.formErrors[key]}</p>
                    </Form.Group>;
                }
            }
            fields.push(field);
        })

        return fields;
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        let fields = this.state.fields;
        fields[name].value = value;
        this.setState({fields},() => { this.validateField(name, value) });
        // console.table(fields);
    }

    handleUserSelect (e) {
        const name = e.target.name;
        const value = FIELDS[name].options[e.target.selectedIndex].key; //e.target.value;
        let fields = this.state.fields;
        fields[name].value = value;
        this.setState({fields},() => { this.validateField(name, value) });
        // console.table(fields);
    }

    childHandleUserInput (e,parent,index) {
        const name = e.target.name;
        const value = e.target.value;
        let fields = this.state.fields;
        fields[parent].children[index].value = value;
        this.setState({fields},() => { });

    }

    toggleDate(e) {
        const input = $(`input[name=${e.target.name}]`);
        input.trigger('click');
    }

    handleDateInput (e) {
        const input = $(`input[name=${e.target.name}]`);
        input.attr(
            "data-date",
            moment(e.target.value, "YYYY-MM-DD")
                .format( input.attr("data-date-format") )
        )
        const name = e.target.name;
        const value = e.target.value;
        if (!value) return;
        let fields = this.state.fields;
        fields[name].value = value;
        this.setState({fields},() => { this.validateField(name, value) });
        // console.table(fields);
    }

    handleSubmit(event){
        if (!this.state.confirm && !this.props.hidePolicy){
            return alert('fill')
        }
        event.preventDefault();
        console.log('form is valid: ', !this.state.formInvalid);
        // console.table(this.state.formErrors);
        // console.table(this.state.validation);
        // console.table(this.state.fields);
        if (!this.state.formInvalid && this.props.callback){
            this.props.callback(cloneDeep(this.state.fields), this.refs);
        }
    }

    confirm(event){
        this.setState({confirm:event.target.checked})
    }

    togglePolicy(event){
        let policy = this.state.openPolicy;
        this.setState({openPolicy:!policy})
    }

    render () {
        return (
            <div className={`padd10 form layout layout-align-center-center`} >
                <Form ref={'formRef'} className={`flex-100`} {...this.props.formOptions}
                      onSubmit={this.handleSubmit.bind(this)}>
                    {this.getFormFields()}

                    {!this.props.hidePolicy && <Form.Group controlId="formBasicChecbox" className="display-flex flex-100 layout layout-row layout-align-space-between-center ">
                        <span className={"display-flex"}>
                            <Form.Check label="" type="checkbox" required inline={true}
                                        onChange={this.confirm.bind(this)}/>
                            <a onClick={this.togglePolicy.bind(this)} className={'privacyButton'}>
                                <span className={'color-a'}>קראתי את התקנון ואני מאשר</span>
                            </a>
                        </span>
                    </Form.Group>}
                    <div className="display-flex width100 layout-align-center-center justify-content-center">
                    {this.props.loading && <Spinner className={"marginTop10px marginBottom10px"} animation="border" />}
                    {!this.props.loading && <Button className={"marginTop10px marginBottom10px"} disabled={false} variant="primary" type="submit">{this.props.submitText || "שלח"}</Button>}
                    </div>
                </Form>

                <div className="layout layout-align-center-center">
                    {false && <FormErrors formErrors={this.state.formErrors} />}
                </div>

                <Privacy show={this.state.openPolicy} onClose={this.togglePolicy.bind(this)} />
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions;

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PCSForm);
