import keyMirror from 'key-mirror';

export default {
  APP : keyMirror({
    SET_MOBILE_VIEW: null,
    SET_LOGIN:null,
    SET_TOKEN:null,
    SET_RENTERS:null,
    LOGOUT:null,
    UPDATE_MODAL:null,
    RESET_MODAL:null,
    UPDATE_FOOTER:null,
  }),
}
