const InitialState = {
    username: "",
    token:"",
    user:{},
    renters:[],
    mobileView:false,
    modal:null,
    footer:{
        goBack:false,
        edit:false,
        aboutUs:true,
        phone:true
    }
};

export default InitialState;
