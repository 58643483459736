import React, { Component } from "react";
import Form from '../components/Form'
import {getPaymentForm} from '../config';
import rest from "../rest";
import * as app_actions from "../store/app/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import cloneDeep from 'lodash/cloneDeep';
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as ICONS from "@fortawesome/free-solid-svg-icons";

class Payment extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            sendAnyway: '',
        }

        if (!this.props.params.residentid){
            this.goBack();
        } else {
            this.residentId = this.props.params.residentid;
        }

        this.props.actions.updateFooter({
            goBack:true,
            edit:false,
            aboutUs:true,
            phone: true
        })
    }

    submit(fields){
        this.setState({loading: true});
        fields.payment_number.children.unshift({
            value:fields.payment_number.value,
        })
        const firstCheck = moment(fields.date.value).utc(true).subtract(1, 'month');
        let toS = {
            Op:"Rental.SendDeal",
            ResidentID: this.residentId,
            TotalValue:12*fields.first_payment.value,
            Payments:12,
            Tehuda:fields.id.value,
            bankid:fields.bank_id.value,
            bank:fields.bank_name.value,
            snifid:fields.branch_number.value,
            account:fields.account_number.value,
            Recs:fields.payment_number.children.map(v=>{
                return ({
                    Amount:fields.first_payment.value,
                    Date: firstCheck.add(1, 'month').format('YYYY-MM-DD'),
                    Serial:v.value,
                })
            }),
        }
        if (fields.guarantee_id && fields.guarantee_id.value){
            toS.garrantID=fields.guarantee_id.value;
        }
        if (this.state.sendAnyway) {
            toS[this.state.sendAnyway] = 1;
        }
        rest.send_payment(toS)
            .then((data)=>{
                let { history } = this.props;
                this.props.actions.updateModal({
                    body: "נשלח לבדיקה",
                    show: true
                })
                history.goBack();
            })
            .catch((err)=>{
                if (err.error && Number(err.error) >= 220) {
                    const key = err.objname;
                    this.props.actions.updateModal({
                        show: true,
                        body: (err.errormsg),
                        buttons: [{
                            name: 'כן',
                            callback: () => {
                                this.setState({sendAnyway: key});
                                this.props.actions.resetModal();
                            }
                        }, {
                            name: 'לא',
                            callback: () => {
                               this.props.actions.resetModal();
                            }
                        }]
                    });
                } else {
                    this.props.actions.updateModal({body: err.errormsg, show: true});
                }
            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }

    goBack(){
        let { history } = this.props;
        history.goBack();
    }

    render () {
        return (
            <div className={'paymentPage flex-100'}>
                    <h3 className="display-flex flex layout-align-center-center width100 padd15px">
                        הזנת פרטי צ'ק
                        <FontAwesomeIcon icon={ICONS.faFileAlt} style={{
                            width: '1.575em',
                            marginTop: 6,
                            marginRight: 10
                        }}/>
                    </h3>
                    <Form fields={getPaymentForm()}
                      submitText={"שלח"}
                      hidePolicy={true}
                      callback={this.submit.bind(this)}
                      loading={this.state.loading}/>
            </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
