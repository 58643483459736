import React, { Component } from "react";
import Form from '../components/Form'
import {getOwnerForm} from '../config';
import rest from '../rest';
import {bindActionCreators} from 'redux';
import * as app_actions from '../store/app/actions';
import {connect} from "react-redux";

class EditOwnerDetails extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            ownerForm: getOwnerForm()
        }
        this.props.actions.updateFooter({
            goBack:true,
            edit:false,
            aboutUs:true,
            phone: true
        })
    }

    componentWillMount() {
        const toS = {
            Op: "Rental.GetAccountInfo"
        }
        rest.getOwnerDetails(toS)
            .then((data)=>{
                const ownerForm = this.state.ownerForm;
                ownerForm.property_owner_name.value = data.firstname;
                ownerForm.property_owner_last_name.value = data.lastname;
                ownerForm.property_owner_phone.value = data.phone;
                ownerForm.property_owner_id.value = data.tehuda;
                ownerForm.property_owner_email.value = data.email;
                ownerForm.property_address.value = data.address;
                ownerForm.property_purpose.value = data.propertykind;
                this.setState({ownerForm: ownerForm});
            })
            .catch(()=>{
                this.props.actions.updateModal({show: true});
            })
    }

    submit(fields) {
        this.setState({loading: true});
        let toS = {
            Op:"Rental.EditResident",
            Tehuda:fields.property_owner_id.value,
            FirstName:fields.property_owner_name.value,
            LastName:fields.property_owner_last_name.value,
            Phone:fields.property_owner_phone.value,
            Email:fields.property_owner_email.value,
            Address:fields.property_address.value,
            PropertyKind:fields.property_purpose.value,
        }
        rest.editOwnerDetails(toS)
            .then(()=>{
                let { history } = this.props;
                this.props.actions.updateModal({
                    body: "הפרטים נשמרו",
                    show: true
                })
                history.goBack();
            })
            .catch(()=>{

            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }

    render () {
        return (
            <div className={'editDetailsPage flex-100'}>
                <Form fields={this.state.ownerForm} hidePolicy={true} callback={this.submit.bind(this)} loading={this.state.loading}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOwnerDetails);
