import React, { Component } from "react";
import Form from '../components/Form'
import {getRegistrationForm} from '../config';
import rest from '../rest';
import * as app_actions from "../store/app/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import RegisterCss from '../css/register.css';
import RegisterScss from '../css/register.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as ICONS from "@fortawesome/free-solid-svg-icons";

class Register extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false
        }
        this.props.actions.updateFooter({
            goBack:false,
            edit:false,
            aboutUs:true,
            phone: true
        })
    }

    submit(fields) {
        this.setState({loading: true});
        let toS = {
            Op:"Rental.Register",
            Tehuda:fields.property_owner_id.value,
            FirstName:fields.property_owner_name.value,
            LastName:fields.property_owner_last_name.value,
            Phone:fields.property_owner_phone.value,
            Email:fields.property_owner_email.value,
            Address:fields.property_address.value,
            PropertyKind:fields.property_purpose.value,
        }
        rest.register(toS)
            .then(()=>{
                let { history } = this.props;
                this.props.actions.updateModal({
                    show: true,
                    body: "נשלח קוד כניסה למכשיר הטלפון",
                })
                history.push({
                    pathname: '/',
                    query: "",
                });
            })
            .catch(()=>{
                this.setState({loading: false});
            })
            .finally(()=>{

            })
    }

    render () {
        return (
            <div className={'registerPage flex-100'}>
                <div className={'register-page-wrapper flex-100 padd10 layout layout-column layout-align-center-start'}>
                    <div className={'flex-100 width100 paddBottom10px'}>
                        <div className={"register-background flex-100 width100"}></div>
                    </div>
                    <h5 className={"paddBottom10px"}>
                        בעל/ת נכס? מגיע לך לקבל משוכרים צ׳קים בביטחון!
                    </h5>
                    <p className={"paddBottom10px"}>
                        באפשרותך לבצע כאן ועכשיו בדיקת היתכנות פרעון לצ׳ק.
                    </p>
                    <p className={"paddBottom10px"}>
                        ככל שיש היתכנות, תוכל לשדרג את השירות להבטחת פרעון הצ׳ק וליהנות מזיכוי על צ׳ק חוזר תוך 7 ימי עסקים.
                    </p>
                    <p className={"paddBottom10px"}>
                        המסלול הדיגיטלי שלנו מאפשר לך לקבל ביטחון לצ׳קים באופן מיידי במספר שלבים פשוטים.
                    </p>
                    <p>כך תקבל/י ביטחון לצ׳קים</p>
                    {/*<ol>
                        <li>מילוי פרטיך ופרטי הדייר לפתיחת כרטיס משתמש</li>
                        <li>אישור הדייר לבדיקה</li>
                        <li>מילוי פרטי הצ׳ק לתשובה מיידית להיתכנות פרעון</li>
                        <li>במידה והתקבלה תשובה חיובית להיתכנות פרעון ניתן לבדוק זכאות להבטחת פרעון</li>
                        <li>אפשר לישון בשקט בלילה! יש לך ביטחון מלא לצ׳קים של הדייר</li>
                    </ol>*/}


                    {/*<article id="timeline">
                        <p>מילוי פרטיך ופרטי הדייר לפתיחת כרטיס משתמש</p>
                        <p>אישור הדייר לבדיקה</p>
                        <p>מילוי פרטי הצ׳ק לתשובה מיידית להיתכנות פרעון</p>
                        <p>במידה והתקבלה תשובה חיובית להיתכנות פרעון ניתן לבדוק זכאות להבטחת פרעון</p>
                        <p>אפשר לישון בשקט! יש לך ביטחון מלא לצ׳קים של הדייר</p>
                    </article>*/}

                </div>
                <Form fields={getRegistrationForm()} callback={this.submit.bind(this)} loading={this.state.loading}/>
            </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
