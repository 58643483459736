const { APP } = require("../actions_type").default;
const initialState = require('./initial').default;

export default function appReducer(state = initialState, action) {
  let newState  = {...state}
  switch (action.type) {
    case APP.SET_LOGIN:{
      newState.token = action.data.token;
      newState.username = action.data.username;
      newState.user = action.data;
      break;
    }

    // case APP.SET_RENTERS:{
    //   newState.renters = action.data;
    //   break;
    // }

    case APP.LOGOUT:{
      newState.user = {};
      newState.username = null;
      newState.token = "";
      break;
    }

    case APP.SET_MOBILE_VIEW:{
      newState.mobileView = action.data.mobileView;
      break;
    }

    case APP.UPDATE_MODAL:{
      newState.modal = action.data;
      break;
    }

    case APP.RESET_MODAL:{
      newState.modal = null;
      break;
    }

    case APP.UPDATE_FOOTER:{
      newState.footer = action.data;
      break;
    }

    default:
      return newState;
  }

  return newState
}
