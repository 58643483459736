import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './init';

export function configureStore() {
    return createStore(rootReducer,applyMiddleware(thunk));
}

const index = configureStore();
export default index;
