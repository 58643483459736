import React from 'react';
import {bindActionCreators} from 'redux';
import * as app_actions from './store/app/actions';
import Register from './pages/Register';
import Login from './pages/Login';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import Payment from './pages/Payment';
import EditOwnerDetails from './pages/EditOwnerDetails';
import NotFound from './pages/NotFound';
import {connect} from 'react-redux';
import { HashRouter, BrowserRouter, Switch, Route, Redirect, Link} from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import './css/App.css';
import {isMobileView} from "./utils";
import store from './store';
import Modal from './components/Modal';
import {ButtonToolbar, ButtonGroup, Button, Navbar, Nav, NavDropdown, Container, Row, Col, Image} from 'react-bootstrap';
import $ from 'jquery';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as ICONS from "@fortawesome/free-solid-svg-icons";
import RecoverPassword from "./pages/RecoverPassword";
const logo = './assets/PCSGLOBALLOGOPNG.png';
const img1 = './assets/secure1.png';
const img2 = './assets/secure-iso.png';
/*let { history } = this.props;
history.push({
    pathname: '/results',
    query: payload,
    questions: data.questions
});
this.props.location.questions*/

function select(state) {
    return state.app.token;
}

let currentValue;
function handleChange() {
    let previousValue = currentValue
    currentValue = select(store.getState())

    // console.log('token changed',previousValue, currentValue)
}

const unsubscribe = store.subscribe(handleChange)

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route render={(props) =>
        {
            return (
                rest.app.token
                ? <Component {...props} {...rest.computedMatch} />
                : <Redirect to={{pathname: '/',}} />
            )
        }

    } />
)

class App extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showModal:false,
            collapsed: true
        };
        window.addEventListener('resize', this.onDimensionsChanged.bind(this));
        // $(document).on('hidden.bs.modal', () => {
        //     console.log(123456)
        // });
        // $(document).on('show.bs.modal', () => {
        //     console.log('asdfgh')
        // });
    }

    componentWillMount() {
        this.onDimensionsChanged();
    }

    onDimensionsChanged(){
        this.props.actions.setMobileView(isMobileView())
        if (window.innerHeight < 600) {
            $('body').addClass('smallHeight');
        } else {
            $('body').removeClass('smallHeight');
        }
    }

    goToLogin(){
        setTimeout(this.closeNavbar.bind(this),250)
        let { history } = this.refs.browserRef;
        history.replace({
            pathname: "/",
        });
    }

    logout(){
        setTimeout(this.closeNavbar.bind(this),250)
        this.props.actions.logout();
    }

    handleSelect(eventKey){
        setTimeout(this.closeNavbar.bind(this),250)
        let { history } = this.refs.browserRef;
        history.push({
            pathname: eventKey,
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    closeNavbar() {
        if (this.state.collapsed === false) {
            this.toggleNavbar();
        }
    }

    render () {
        return (
            <React.Fragment>

                <Navbar sticky="top" bg="light" expand="md" expanded={!this.state.collapsed}>
                    {false && window.history.length && <Navbar.Brand onClick={()=>window.history.back()}>
                        <FontAwesomeIcon icon={ICONS.faArrowRight}/>
                    </Navbar.Brand>}
                    <Navbar.Brand href={this.props.app.token?"/home":"/"}>
                        <img className={"logo"} src={logo}/>
                        <span>פי.סי.אס גלובל</span>
                    </Navbar.Brand>
                    <Navbar.Toggle ref={"navbartoggle"} aria-controls="basic-navbar-nav"
                                   children={
                                       <FontAwesomeIcon icon={ICONS.faBars}/>
                                       // <i className={'fa fa-bars'}></i>
                                   }
                                   onClick={this.toggleNavbar.bind(this)}/>
                    <Navbar.Collapse ref={"navbarcollapse"} id="basic-navbar-nav">
                        <Nav className="ml-auto" variant="pills" defaultActiveKey={window.location.pathname} onSelect={this.handleSelect.bind(this)}>
                            {!this.props.app.token && <Nav.Item className={`${this.props.app.mobileView && 'marginTop5px'} text-right`}>
                                <Nav.Link eventKey="/register">הירשם</Nav.Link>
                            </Nav.Item>}
                            {this.props.app.token && <Nav.Item className={`${this.props.app.mobileView && 'marginTop5px'} text-right`}>
                                <Nav.Link eventKey="/home">שוכרים</Nav.Link>
                            </Nav.Item>}
                            {this.props.app.token && <Nav.Item className={`${this.props.app.mobileView && 'marginTop5px'} text-right`}>
                                <Nav.Link eventKey="/edit">פרופיל</Nav.Link>
                            </Nav.Item>}
                            {<Nav.Item className={`${this.props.app.mobileView && 'marginTop5px'} text-right`}>
                                <Nav.Link eventKey="/about-us">מי אנחנו</Nav.Link>
                            </Nav.Item>}
                            {<Nav.Item className={`${this.props.app.mobileView && 'marginTop5px'} text-right`}>
                                <Nav.Link eventKey="/contact-us">צור קשר</Nav.Link>
                            </Nav.Item>}
                        </Nav>
                        <Nav className="mr-auto">
                            <div className="layout layout-align-center-center padd10">
                                <a href="tel:03-525-7777">
                                    {/*<i className='fa fa-phone padd5 text-white'></i>*/}
                                    <FontAwesomeIcon className="marginLeft5px text-white" icon={ICONS.faPhone}/>
                                    <span className={"text-white"}>
                                      <b>03-5257777</b>
                                    </span>
                                </a>
                            </div>
                            {!this.props.app.token && <Button variant="outline-light" onClick={this.goToLogin.bind(this)}>התחבר</Button>}
                            {this.props.app.token && <Button variant="outline-light" onClick={this.logout.bind(this)}>התנתק</Button>}
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>

                {<Modal show={this.props.app.modal && this.props.app.modal.show}
                        onHide={this.props.actions.resetModal}
                        onClose={this.props.actions.resetModal}
                        title={this.props.app.modal && this.props.app.modal.title}
                        body={this.props.app.modal && this.props.app.modal.body}
                        buttons={this.props.app.modal && this.props.app.modal.buttons}/>}

                {/* BrowserRouter       HashRouter*/}
                <BrowserRouter ref={'browserRef'} className={`App flex-100 layout layout-align-center-center`}>
                  <Switch>
                      <Route exact path="/" component={Login}/>
                      <Route path="/register" component={Register} />
                      <Route path="/recover/:id?/:property_renter_phone?" component={RecoverPassword}/>
                      <Route path="/about-us" component={AboutUs}/>
                      <Route path="/contact-us" component={ContactUs}/>
                      <PrivateRoute path="/edit" component={EditOwnerDetails} {...this.props}/>
                      <PrivateRoute path='/home' component={Home} {...this.props}/>
                      <PrivateRoute path='/payment/:residentid' component={Payment} {...this.props}/>

                      <Route component={NotFound} />
                 </Switch>
                </BrowserRouter>

                {false && <footer className="footer app-footer d-flex flex-column security">
                        <Row style={{margin: 0}}>
                            <Col style={{
                                padding: 0,
                                'align-items': 'flex-end',
                                display: 'flex',
                                'flex-direction': 'row-reverse',
                            }}>
                                <img src={img2} width={65} style={{'padding-right': '5px'}}></img>
                                <img src={img1}></img>
                            </Col>
                        </Row>
                </footer>}
            </React.Fragment>
        )
    };
}

function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
