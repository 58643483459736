import React, { Component } from "react";

class Register extends Component {
    constructor (props) {
        super(props);
        this.state = {

        }

    }

    render () {
        return (
            <div className={'flex-100 padd10'} style={{width: '95%'}}>
               <h1>404</h1>
                <br/>
               <h3>הדף לא נמצא</h3>
            </div>
        )
    }
}
export default Register;
