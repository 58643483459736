import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as ICONS from '@fortawesome/free-solid-svg-icons';

class FloatingMenuItem extends React.Component {

    handleClick() {
        this.props.action();
    }

    render() {
        let buttonStyle = {
            backgroundImage: `url(${this.props.icon})`
        }

        let label;

        if (this.props.label) {
            label = <label>{this.props.label}</label>;
        }

        return <div
            onClick={this.handleClick.bind(this)}
            className={`floating-menu-item ${this.props.class}`}>
            <div className="floating-menu-icon"><FontAwesomeIcon color={'white'} icon={this.props.icon}/></div>
            {label}
        </div>;
    }
}

export default class FloatingMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggled: this.props.open
        }
    }



    componentDidUpdate() {
        document.getElementById("root").addEventListener("click", ()=>{

        }, true);
    }

    toggleMenu() {
        this.setState({toggled: !this.state.toggled});
    }

    render() {
        let buttons = [];
        let className = "floating-menu";
        let icon = ICONS.faPlus;

        if (this.state.toggled) {
            className += " open";
            icon = ICONS.faPlus;
            this.props.buttons.forEach((button)=>{
                buttons.push(
                    <FloatingMenuItem label={button.label} icon={button.icon} action={button.action} key={button.key}/>);
            })
        } else {
            className += " closed";
        }

        buttons.push(<FloatingMenuItem class="mainMenuIcon" label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>);

        return <div className="container">
            <div className={className}>
                {buttons}
            </div>
        </div>;
    }
}
