import cloneDeep from 'lodash/cloneDeep';
import keyMirror from 'key-mirror';
import moment from 'moment';
import * as ICONS from '@fortawesome/free-solid-svg-icons';

export const LOCAL_STORAGE = keyMirror({
    LOGIN_INFO:null,
    TOKEN:null,
    USERNAME:null,
})

export const banks = [
    {value:"בחר בנק",key:0},
    {value:'בנק יהב לעובדי המדינה בע"מ',key:"04"},
    {value:'בנק הדואר',key:"09"},
    {value:'בנק לאומי לישראל בע"מ',key:10},
    {value:'בנק דיסקונט לישראל בע"מ',key:11},
    {value:'בנק הפועלים בע"מ',key:12},
    {value:'בנק אגוד לישראל בע"מ',key:13},
    {value:'בנק אוצר החייל בע"מ',key:14},
    {value:'בנק מרכנתיל דיסקונט בע"מ',key:17},
    {value:'בנק מזרחי טפחות בע"מ',key:20},
    {value:'Citibank N.A',key:22},
    {value:'HSBC  Bank plc',key:23},
    {value:'BNP Paribas Israel',key:25},
    {value:'יובנק בע"מ',key:26},
    {value:'הבנק הבינלאומי הראשון לישראל בע"מ',key:31},
    {value:'בנק ערבי ישראלי בע"מ',key:34},
    {value:'SBI State Bank of India',key:39},
    {value:'בנק מסד בע"מ',key:46},
    // {value:'מרכז סליקה בנקאי בע"מ',key:50},
    {value:'בנק פועלי אגודת ישראל בע"מ',key:52},
    {value:'בנק ירושלים בע"מ',key:54},
    // {value:'שירותי בנק אוטומטיים בע"מ',key:59},
    {value:'חסך קופת חסכון לחינוך בע"מ, חיפה',key:65},
    {value:'בנק דקסיה ישראל בע"מ',key:68},
    {value:'בנק לאומי למשכנתאות בע"מ',key:77},
    {value:'בנק דיסקונט למשכנתאות בע"מ',key:90}
];

export const statuses = {
    "":{
        text:"Please Wait"
    },
    "1":{
        text:"New Resident",
        icon:ICONS.faUserClock,
        iconHover:"אנא המתן"
    },
    "2":{
        text:"SMS sent to resident",
        icon:ICONS.faUserClock,
        iconHover:"אנא המתן"
    },
    "3":{
        text:"Resident approved, Waiting for payment",
        clickToPayment:true,
        icon:ICONS.faCreditCard,
        iconHover:"לחץ לתשלום"
    },
    "4":{
        text:"Payment Accepted",
        enterDealInformation:true,
        icon:ICONS.faPen,
        iconHover:"הזן פרטי צ׳ק"
    },
    "5":{
        text:"Deal information accepted – in test",
        icon:ICONS.faUserClock,
        iconHover:"אנא המתן"
    },
    "100":{
        text:"Deal approved first stage",
        requestInsurance:true,
        icon:ICONS.faThumbsUp,
        iconHover:"יש היתכנות פירעון"
    },
    "101":{
        text:"Waiting for Moked approval",
        icon:ICONS.faUserClock,
        iconHover:"אנא המתן"
    },
    "102":{
        text:"Deal is NOT approved for insurance",
        icon:ICONS.faLockOpen,
        iconHover:"אין אבטחת פירעון"
    },
    "200":{
        text:"Deal approved for Insurance, wait for payment",
        clickToPayment:true,
        icon:ICONS.faCreditCard,
        iconHover:"לחץ לתשלום"
    },
    "500":{
        text:"Payment accepted, deal is insured",
        icon:ICONS.faLock,
        iconHover:"יש הבטחת פירעון"
    },
    "-1":{
        text:"Deal was not approved for first state",
        icon:ICONS.faThumbsDown,
        iconHover:"אין היתכנות פירעון"
    },
}

export const getRegistrationForm = ()=> {
    let form = {
        property_owner_name: {
            value: '',
            title: 'בעל הנכס (שם פרטי)',
            type: 'text',
        },
        property_owner_last_name: {
            value: '',
            title: 'בעל הנכס (שם משפחה)',
            type: 'text',
        },
        property_owner_phone: {
            value: '',
            title: 'טלפון',
            settings:{pattern: '[0-9]*', maxLength: 20, minLength: 9, maxlength: 20, minlength: 9},
            type: 'tel',
        },
        property_owner_email: {
            value: '',
            title: 'אימייל',
            type: 'email',
        },
        property_owner_id: {
            value: '',
            title: 'תעודת זהות',
            settings: {pattern: '[0-9]*', maxLength: 9, minLength: 9, maxlength: 9, minlength: 9},
            type: 'text',
        },
        property_address: {
            value: '',
            title: 'כתובת',
            type: 'text',
        },
        property_purpose: {
            value: '',
            title: 'מטרת הנכס',
            type: 'select',
            options: [{key: 0, value: '',}, {key: "P", value: 'פרטי'}, {key: "M", value: 'מסחרי'}]
        },
    }
    return cloneDeep(form);
};

export const getOwnerForm = ()=> {
    let form = {
        property_owner_name: {
            value: '',
            title: 'בעל הנכס (שם פרטי)',
            type: 'text',
        },
        property_owner_last_name: {
            value: '',
            title: 'בעל הנכס (שם משפחה)',
            type: 'text',
        },
        property_owner_phone: {
            value: '',
            title: 'טלפון',
            settings:{pattern: '[0-9]*', maxLength: 20, minLength: 9, maxlength: 20, minlength: 9},
            type: 'tel',
        },
        property_owner_email: {
            value: '',
            title: 'אימייל',
            type: 'email',
        },
        property_owner_id: {
            value: '',
            title: 'תעודת זהות',
            settings:{disabled: 'true'},
            type: 'text',
        },
        property_address: {
            value: '',
            title: 'כתובת',
            type: 'text',
        },
        property_purpose: {
            value: '',
            title: 'מטרת הנכס',
            type: 'select',
            options: [{key: 0, value: '',}, {key: "P", value: 'פרטי'}, {key: "M", value: 'מסחרי'}]
        },
    }
    return cloneDeep(form);
};

export const getRenterForm = ()=> {
    let form = {
        property_renter_name: {
            value: '',
            title: 'שם פרטי',
            type: 'text',
        },
        property_renter_last_name: {
            value: '',
            title: 'שם משפחה',
            type: 'text',
        },
        property_renter_id: {
            value: '',
            title: 'תעודת זהות',
            type: 'number',
        },
        property_renter_phone: {
            value: '',
            title: 'טלפון',
            settings:{pattern: '[0-9]*', maxLength: 20, minLength: 9, maxlength: 20, minlength: 9},
            type: 'tel',
        },
    }
    return cloneDeep(form);
};

export const getLoginForm = ()=> {
    let form = {
        id: {
            value: '', // 100000009
            title: 'ת״ז או ח״פ',
            settings:{pattern: '[0-9]*'},
            type: 'number',
        },
        property_renter_phone: {
            value: '', // 0501111111
            title: 'טלפון',
            settings:{pattern: '[0-9]*'},
            type: 'tel',
        },
        property_renter_password: {
            value: '', // pikachu
            title: 'סיסמא',
            type: 'password',
        },
    }
    return cloneDeep(form);
};

export const getRecoverPassForm = (params)=> {
    let form = {
        id: {
            value: params.id, // 100000009
            title: 'ת״ז או ח״פ',
            settings:{pattern: '[0-9]*'},
            type: 'number',
        },
        property_renter_phone: {
            value: params.property_renter_phone, // 0501111111
            title: 'טלפון',
            settings:{pattern: '[0-9]*'},
            type: 'tel',
        },
    }
    return cloneDeep(form);
};

export const getPaymentForm = ()=>{
    let form = {
        /*total_payment: {
            value: '',
            title: 'סכום עסקה',
            settings:{pattern: '[0-9]*'},
            type: 'number',
        },*/
        /*payments_number: {
            value: '',
            title: 'מספר תשלומים',
            settings:{pattern: '[0-9]*'},
            type: 'number',
        },*/
        first_payment: {
            value: '',
            title: 'סכום צ׳ק חודשי',
            subTitle: '(1 מתוך 12)',
            settings:{pattern: '[0-9]*'},
            type: 'number',
        },
        payment_number: {
            value: '',
            title: 'מספר צ׳ק ראשון',
            settings:{pattern: '[0-9]*'},
            type: 'number',
            children:[1,2,3,4,5,6,7,8,9,10,11].map(()=>{
                return {
                    value: '',
                    title: 'מספר צ׳ק',
                    settings:{pattern: '[0-9]*'},
                    type: 'number',
                    placeholder: 'במידה ומספרי הצ׳ק אינם בסדר רציף'
                }

            })
        },
        date: {
            value: '',
            title: 'תאריך',
            settings:{
                min: moment().format('YYYY-MM-DD'),
                max: moment().add(1, 'month').format('YYYY-MM-DD'),
                'data-date-format': "DD-MM-YYYY",
            },
            type: 'date',
        },
        bank_id: {
            value: '',
            title: 'בנק',
            settings:{
                pattern: '[0-9]*',
            },
            type: 'number',
        },
        bank_name: {
            value: '',
            title: 'שם בנק',
            settings:{
                pattern: '[0-9]*',
            },
            options: cloneDeep(banks),
            type: 'select',
        },
        branch_number: {
            value: '',
            title: 'מס׳ סניף',
            settings:{pattern: '[0-9]*'},
            type: 'number',
        },
        account_number: {
            value: '',
            title: 'מס׳ חשבון',
            settings:{pattern: '[0-9]*'},
            type: 'number',
        },
        id: {
            value: '',
            title: 'ת״ז או ח״פ',
            settings:{pattern: '[0-9]{9}', maxLength: 9},
            type: 'number',
        },
        guarantee_id: {
            value: '',
            title: 'ת״ז ערב',
            settings:{pattern: '[0-9]{9}', maxLength: 9},
            type: 'number',
        },
    }
    return cloneDeep(form);
}

export const getContactUsForm = ()=> {
    let form = {
        name: {
            value: '',
            title: 'שם מלא',
            type: 'text',
        },
        phone: {
            value: '',
            title: 'טלפון',
            settings:{pattern: '[0-9]*'},
            type: 'tel',
        },
        message: {
            value: '',
            settings:{rows: 3},
            title: 'הודעה',
            type: 'textarea',
        },
    }
    return cloneDeep(form);
};

