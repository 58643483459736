import {LOCAL_STORAGE} from '../../config';
const {APP} = require("../actions_type").default;

export function init() {
  return (dispatch, getState) => {
    let login_info = window.sessionStorage.getItem(LOCAL_STORAGE.LOGIN_INFO);
    if (login_info){
      try{
        login_info = JSON.parse(login_info);
        dispatch({
          type: APP.SET_LOGIN,
          data: login_info
        })
      } catch(err){}
    } else {
      if (window.location.hostname === 'localhost') {
        dispatch({
          type: APP.SET_LOGIN,
          data: login_info || {token: "123", username: 'avi'}
        })
      }
    }
  }
}

export function setMobileView(isMobile) {
  return (dispatch, getState) => {
    dispatch({
      type: APP.SET_MOBILE_VIEW,
      data: {mobileView:isMobile}
    })
  }
}

export function login(data) {
  return (dispatch, getState) => {
    return new Promise((resolve,reject)=>{
      window.sessionStorage.setItem(LOCAL_STORAGE.LOGIN_INFO, JSON.stringify(data));
      dispatch({
        type: APP.SET_LOGIN,
        data: data
      })
      resolve();
    })

  }
}

// export function set_renters(data) {
//   return (dispatch, getState) => {
//     dispatch({
//       type: APP.SET_RENTERS,
//       data: data
//     })
//   }
// }

export function logout() {
  return (dispatch, getState) => {
    window.sessionStorage.removeItem(LOCAL_STORAGE.LOGIN_INFO);
    dispatch({
      type: APP.LOGOUT,
      data: null
    })
  }
}


export function updateModal(data) {
  return (dispatch, getState) => {
    dispatch({
      type: APP.UPDATE_MODAL,
      data: data
    })
  }
}


export function resetModal() {
  return (dispatch, getState) => {
    console.log('reset modal');
    dispatch({
      type: APP.RESET_MODAL,
      data: null
    })
  }
}


export function updateFooter(data) {
  return (dispatch, getState) => {
    dispatch({
      type: APP.UPDATE_FOOTER,
      data: data
    })
  }
}
