import React, { Component,useState } from "react";
import {Modal, Button, Spinner,} from 'react-bootstrap';
import Form from "./Form";
import {getRenterForm} from "../config";
import * as app_actions from "../store/app/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import rest from '../rest';
// import '../css/IPay.css';

class IPay extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading:true,
            show: props.show
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) this.handleShow();
        else this.handleClose();
    }

    handleClose() {
        this.setState({show:false});
    }

    handleShow() {
        this.setState({show:true});
    }

    onClose(status = true) {
        this.handleClose();
        this.props.onClose(status);
    }

    submit(fields){

    }

    render () {
        return (
            <Modal size="lg" className={"IPAY"}
                   aria-labelledby="contained-modal-title-vcenter"
                   top={"true"}
                   backdrop="static"
                   show={this.state.show}
                   onHide={this.onClose.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title className={'flex-100 layout layout-row layout-align-center-center'}>{"תשלום מאובטח"}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-center">
                    {this.state.loading && <Spinner className={"marginTop10px marginBottom10px"} animation="border" />}
                    {this.props.resident && this.props.resident.url &&
                        <iframe src={this.props.resident.url} className={this.state.loading ? 'display-none' : ''}
                            onLoad={()=>this.setState({loading: false})}
                            frameBorder={"none"}
                            height="550px"
                            width="100%"
                            name={this.props.resident.id}
                            id={this.props.resident.id}
                        >
                        <p>Your browser does not support I-FRAME.</p>
                    </iframe>}
                </Modal.Body>
            </Modal>
        )
    }
}



function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions;

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IPay);
