import React, { Component } from "react";
import {bindActionCreators} from 'redux';
import * as app_actions from '../store/app/actions';
import "../css/Login.css";
import Form from '../components/Form'
import {getRecoverPassForm} from '../config';
import rest from '../rest';
import {connect} from "react-redux";

class RecoverPassword extends Component {
    constructor (props) {
        super(props);
        const {history} = props;
        const search = history.location.query || {};
        this.params = search;

        this.state = {
            loading: false
        }
        this.props.actions.updateFooter({
            goBack:false,
            edit:false,
            aboutUs:true,
            phone: true
        })
    }

    submit(fields){
        this.setState({loading: true});
        let toS = {
            Op:"Rental.RecoverPassword",
            TZ:fields.id.value,
            Phone:fields.property_renter_phone.value,
        }
        rest.login(toS)
            .then(this.props.actions.login)
            .then(()=>{
                let { history } = this.props;
                history.replace({pathname: '/'});
            })
            .catch((err)=>{
                this.props.actions.updateModal({
                    body: err.errormsg,
                    show: true
                });
            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }

    render () {
        return (
            <div className={'loginPage flex-100'}>
                <Form fields={getRecoverPassForm(this.params)} callback={this.submit.bind(this)} loading={this.state.loading}
                      hidePolicy={true}
                />
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);

