import React, { Component,useState } from "react";
import { Modal,Button,} from 'react-bootstrap';
import Form from "./Form";
import {getRenterForm} from "../config";
import * as app_actions from "../store/app/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import rest from '../rest';
import '../css/AddRenter.css';

class AddRenter extends Component {
    constructor (props) {
        super(props);
        this.state = {
            show: props.show
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show) this.handleShow();
        else this.handleClose();
    }

    handleClose() {
        this.setState({show:false});
    }

    handleShow() {
        this.setState({show:true});
    }

    onClose(status) {
        this.handleClose();
        this.props.onClose(status);
    }

    submit(fields){
        let toS = {
            Op:"Rental.NewResident",
            Tehuda:fields.property_renter_id.value,
            FirstName:fields.property_renter_name.value,
            LastName:fields.property_renter_last_name.value,
            PhoneNumber:fields.property_renter_phone.value,
        }
        rest.add_renter(toS)
            .then((data)=>{
                this.onClose(data)
            })
            .catch(()=>{

            })
    }

    render () {
        return (
            <Modal size="lg" className={'add-renter'}
                   aria-labelledby="contained-modal-title-vcenter"
                   top={"true"}
                   show={this.state.show}
                   onHide={this.onClose.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title className={'flex-100 layout layout-row layout-align-center-center'}>{"הוסף שוכר"}</Modal.Title>
                </Modal.Header>

                <Form fields={getRenterForm()}
                      submitText={"הוסף"}
                      hidePolicy={true}
                      callback={this.submit.bind(this)}/>

                {/*<Modal.Footer className={'flex-100 layout layout-row layout-align-space-between-center'}>
                    {this.props.buttons && this.props.buttons.map((btn,i)=><Button key={`_${i}`} variant={btn.class || 'primary'} onClick={()=>{this.handleClose(btn.callback);}}>{btn.name || 'OK'}</Button>)}
                    {!this.props.buttons && <Button variant="primary" onClick={this.handleClose.bind(this)}>OK</Button>}
                </Modal.Footer>*/}
            </Modal>
        )
    }
}



function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions;

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRenter);
