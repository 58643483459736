import rp from 'request-promise';
import { statuses } from './config';
const actions = require('./store/app/actions');
const store = require('./store').default;
const DOMAIN = window.DOMAIN;

let headers = {}
// let headers = new Headers();
// headers.set("Accept", 'application/json, text/plain, */*');
// // headers.set("Origin", "http://apitest.pcsglobal.co.il");
// headers.set("Content-Type", 'application/json');
// headers.set('cache-control', 'no-cache');
// headers.set('sec-fetch-mode', 'cors');
// headers.set('Accept', 'application/json');

function getTempCards() {
    const data = Object.keys(statuses).map((key,index)=>{
        let tmp = {
            name:`${Math.floor(Math.random()*100)} בדיקה אבישי `,
            statuscode:key,
            statusdesc: statuses[key].text,
            buttondesc:"",
            payamount:"",
            tehuda:Date.now(),
            dealid:index,
            residentid:Date.now(),
        }
        if (statuses[key].clickToPayment){
            tmp.buttondesc = "clickToPayment";
            tmp.payamount = 100;
        }
        if (statuses[key].enterDealInformation){
            tmp.buttondesc = "enterDealInformation";
        }
        if (statuses[key].requestInsurance){
            tmp.buttondesc = "requestInsurance";
        }
        return tmp;
    })
    return data;
}

function responseMiddleware(response){
    try{
        if (typeof response === 'string')
            response = JSON.parse(response)
    }catch(err){
        response.error = '-1';
        response.errormsg = '';
    }

    if (response.error!=="0"){
        if (response.error === "-99"){
            store.dispatch(actions.logout());
            throw response;
        } else {
            throw response;
        }
    }
    return response;
}
function catchMiddleware(response) {
    throw response;
}

function getCreds () {
    let Key = store.getState().app.token;
    let Username = store.getState().app.username
    return {
        Key,
        Username
    }
}

const REST = {
    register: (data) => {
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(data)}`,
            method: 'post',
//             headers: headers,
            json: true,
//             body: data
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    editOwnerDetails: (data) => {
        let info = getCreds();
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(Object.assign(data,info))}`,
            method: 'post',
//             headers: headers,
            json: true,
//             body: Object.assign(data,info)
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    login: (data) => {
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(data)}`,
            method: 'post',
            // headers: headers,
            json: true,
            // body: data
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve({TOKEN:"abc",Username:"avishay"});
        // })
    },
    add_renter: (data) => {
        let info = getCreds();
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(Object.assign(data,info))}`,
            method: 'post',
//             headers: headers,
            json: true,
//             body: Object.assign(data,info)
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    get_renters: (data) => {
        let info = getCreds();
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(Object.assign(data,info))}`,
            method: 'get',
            json: true,
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
//         return new Promise((resolve,reject)=>{
//             resolve({
//                 error:"0",
//                 residents:getTempCards()
//             });
//         })
    },
    send_payment: (data) => {
        let info = getCreds();
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(Object.assign(data,info))}`,
            method: 'post',
//             headers: headers,
            json: true,
//             body: Object.assign(data,info)
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    request_insurance: (data) => {
        let info = getCreds();
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(Object.assign(data,info))}`,
            method: 'post',
//             headers: headers,
            json: true,
//             body: Object.assign(data,info)
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    delete_renter: (data) => {
        let info = getCreds();
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(Object.assign(data,info))}`,
            method: 'post',
//             headers: headers,
            json: true,
//             body: Object.assign(data,info)
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    contact_us: (data) => {
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(data)}`,
            method: 'post',
//             headers: headers,
            json: true,
//             body: data
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    getOwnerDetails: (data) => {
        let info = getCreds();
        return rp({
            url:`${DOMAIN}?qr=${JSON.stringify(Object.assign(data,info))}`,
            method: 'get',
//             headers: headers,
            json: true,
//             body: data
        })
            .then(response => {
                return response;
            })
            .then(responseMiddleware)
            .catch(catchMiddleware)
        // return new Promise((resolve,reject)=>{
        //     resolve();
        // })
    },
    getPayURL: (data) => {
        const userKey = "fb8bb542-65a1-4a0f-895d-63500564d899";
        const prod = 'https://icredit.rivhit.co.il/API/PaymentPageRequest.svc/GetUrl';
        const test = 'https://testicredit.rivhit.co.il/API/PaymentPageRequest.svc/GetUrl';
        console.log('req',JSON.stringify({...data, GroupPrivateToken: userKey, Currency:1, DocumentLanguage: 'he', SaleType: 1}));
        return rp({
            url: test,
            method: 'post',
            json: true,
            cors: true,
            body: {...data, GroupPrivateToken: userKey, Currency:1, DocumentLanguage: 'he', SaleType: 1}
        })
            .then(response => {
                if (response.Status != "0") {
                    throw response;
                }
                return response;
            })
            .catch(catchMiddleware)
    }

}

export default REST;
