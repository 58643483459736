import React, { Component } from "react";
import Modal from '../components/Modal';

const policy = <div className="privacy-modal">
    <p>
        תקנון לשימוש באתר - חברת פי.סי.אס גלובל בע"מ
    </p>
    <ol start="1">
        <li>
            תקנון זה מגדיר את התנאים החלים על המשתמשים (להלן: "המשתמש") בעת השימוש באתר (בכתובת:
            <a href={"http://rent.pcsglobal.co.il"}>http://rent.pcsglobal.co.il</a>
            ) (להלן: "האתר") ו/או בשירותים המוצעים באתר על ידי חברת פי. סי. אס גלובל בע"מ ח.פ. 515169506 (להלן: "החברה")
        </li>
        <li>
            על כל משתמש לקרוא תקנון זה בטרם השימוש באתר ו/או באפליקציה ו/או בשירותי החברה. באמצעות סימון תיבת "אני מסכים" באתר ו/או באפליקציה מאשר המשתמש כי הנו  מסכים לתנאי תקנון זה וכי לא תישמענה כל טענה מצדו נגד החברה בקשר לתנאים המפורטים בתקנון זה
        </li>
        <li>
            השימוש באתר מותר למי שמלאו לו 18 שנים בלבד והנו בעל כשרות משפטית ובעל הסמכות הנדרשת לצורך התקשרות עם החברה
        </li>
        <li>
            המשתמש מצהיר כי הנו בעל נכס מקרקעין המיועד לשכירות (להלן ייקרא גם: "בעל הנכס") על ידי צד שלישי (להלן: "בעל השיק" ו/או "שוכר פוטנציאלי") ולצורך כך נדרש לשירותי החברה כהגדרתם להלן
        </li>
    </ol>

    <p>
        הגדרת השירות
    </p>
    <ol start="5">
        <li>
            ידוע למשתמש כי החברה מציעה שירותי בדיקת היתכנות פירעון שיקים (להלן: "בדיקת ההיתכנות") וכן שירותי הבטחת פירעון שיקים (להלן: "הבטחת הפירעון") בהתאם לתנאים המפורטים להלן
        </li>
    </ol>

    <p>
        אופן עריכת בדיקת היתכנות פירעון שיקים
    </p>
    <ol start="6">
        <li>
            בעל הנכס יתבקש להזין באתר את פרטיו האישיים ופרטים נוספים, לרבות, אודות השוכר הפוטנציאלי (בעל השיק) (להלן: "הפרטים")
        </li>
        <li>
            בעל הנכס מצהיר כי ידוע לו כי שירותי החברה ניתנים בעד שיק של תושב ישראלי בלבד
        </li>
        <li>
            בעל הנכס מצהיר כי טרם מסירת הפרטים לחברה ביצע זיהוי של בעל השיק כנגד תעודת זהות ו/או רישיון נהיגה ישראלי
        </li>
        <li>
            בעל הנכס מצהיר כי טרם מסירת הפרטים לחברה הודיע לבעל השיק על כוונתו לפנות לחברה בבקשה לערוך את בדיקת ההיתכנות
        </li>
        <li>
            בעל הנכס מצהיר כי יבצע שימוש בשירותי החברה לצורך בחינת כדאיות עסקאות עתידיות (שוכרים פוטנציאליים) בלבד, והמועד הקובע הינו יום חתימת ההסכם ובתנאי שיחתם הסכם שכירות לא יאוחר מ-30 יום קלנדריים מיום הבדיקה. ידוע לבעל הנכס כי לא ניתן להשתמש בשירותי החברה לצורך בחינת התקשרות קיימת ו/או קודמת וכי לא תישמענה כל טענות בהקשר זה נגד החברה
        </li>
        <li>
            במקרה שבעל השיק אינו אדם פרטי אלא חברה יתבקש בעל הנכס למסור לחברה פרטים של ערב, שהוא בעל מניות בחברה ומורשה חתימה מטעמה, וכל התנאים שלעיל יחולו בשינויים המתחייבים
        </li>
        החברה תבצע קליטה ואימות של הנתונים, באמצעות פניה ישירה לבעל השיק לצורך קבלת הסכמתו לשימוש במידע אודותיו וכן לצורך מסירת הודעה בנוגע לקבלת חיווי אשראי בהתאם לסעיף 10 לכללי נתוני אשראי (הוראות שונות), התשע"ח-2017, בנוסח כדלקמן:
        <li>
            "הודעה בנוגע לקבלת חיווי אשראי: הרינו להודיעך כי בהמשך לבקשה שהוגשה על ידך ו/או מי מטעמך בכוונתנו לפנות ללשכת אשראי (כהגדרתה בחוק נתוני אשראי, התשע"ו-2016) לצורך קבלת חיווי אשראי אודותיך וזאת במסגרת בדיקת היתכנות פירעון שיקים שיימסרו ו/או שנמסרו על ידך, בהתאם להתחייבותך לשלם דמי שכירות. המידע אשר יתקבל, ככל שיתקבל, ישמש אותנו אף לצורך ניהול סיכונים וקבלת החלטה בדבר האפשרות לערוב להתחייבותך הנ"ל כלפי צד ג' הוא בעל הנכס (המושכר)"
        </li>
        <li>
            מובהר למשתמש כי בדיקת ההיתכנות תתבצע על ידי החברה בכפוף לקבלת הסכמה של בעל השיק
        </li>
        <li>
            ידוע למשתמש כי עלות בדיקת ההיתכנות נקבעת על פי שיקול דעתה הבלעדי של החברה מעת לעת כפי שהיא תימסר לו במועד מסירת הפרטים הנדרשים לחברה
        </li>
        <li>
            ידוע למשתמש כי עלות בדיקת ההיתכנות תשולם על ידו מראש וללא קשר לתוצאות הבדיקה ומשכך הינו מודע ומסכים לכך שעלות הבדיקה לא תוחזר לו בשום מקרה
        </li>
        <li>
            בהתאם לבדיקת ההיתכנות שתתבצע על ידי החברה, תימסר למשתמש הודעה בדבר היתכנות פירעון השיק
        </li>
        <li>
            המשתמש מודע לכך כי תוצאת הבדיקה שתימסר על ידי החברה תקפה למועד מסירתה בלבד ויכולה להשתנות בכל עת
        </li>
        <li>
            החברה אינה אחראית לפרטים אשר יוזנו באתר על ידי המשתמש  ו/או מי מטעמו ואין לה כל שליטה על הפרטים באופן שאינה יכולה לבדוק את מהימנותם
        </li>
        <li>
            המשתמש מתחייב להודיע לחברה על כל מקרה בו עולה אצלו ידיעה ו/או חשש ו/או חשד בדבר מידע שגוי ו/או שקרי ו/או כוזב שהועבר לחברה ו/או שנמסר על ידי החברה
        </li>
        הבטחת פירעון שיקים<li>
        לאחר קבלת תוצאת בדיקת היתכנות חיובית יוכל המשתמש לפנות לחברה לצורך הבטחת פירעון השיקים וזאת בתנאי שהשיקים ניתנו על חשבון דמי שכירות בלבד
    </li>
        <li>
            החלטת החברה אם להעניק למשתמש שירותי הבטחת פירעון שיק תינתן על סמך תוצאת בדיקת ההיתכנות ונתונים נוספים לפי הצורך ובהתאם לשיקול דעתה הבלעדי
        </li>
        <li>
            שירותי הבטחת הפירעון יינתנו רק בעבור שיק שפרטיו שודרו באתר ושניתן למשתמש לטובת תשלום דמי שכירות בלבד. אין התחייבות לפירעון שיק שניתן כביטחון ו/או כערבון ו/או לכל צורך אחר שאינו שכירות
        </li>
        <li>
            יובהר כי השירות מותנה בהתחייבות המשתמש לשלם לחברה את העלויות הנדרשות בגין השירות בתנאים שייקבעו על ידי החברה מעת לעת
        </li>
        <li>
            תנאי נוסף להחלת שירות הבטחת הפירעון הינו המצאת הסכם שכירות חתום על ידי המשתמש ובעל השיק לא יאוחר מ-7 ימים ממועד אישור החברה להבטחת פירעון השיקים
        </li>
        <li>
            המשתמש מצהיר כי ידוע לו כי יש חובה לציין בשורת המוטב בכתב יד בכל שיק שיימסר לחברה לצורך הבטחת הפירעון את המילים הבאות: "לפקודת שם המשתמש וחברת פי. סי. אס גלובל בע"מ"
        </li>
        <li>
            במקרה של חילול שיק (שיק חוזר), על המשתמש למסור לחברה הודעה על חילול השיק תוך 21 ימים קלדריים ממועד החילול וכן, להעביר לחברה פלט של שיק ממוחשב שהופק על ידי הבנק לידי החברה וזאת לא יאוחר מ-7 ימי עסקים
        </li>
        <li>
            החברה תעניק זיכוי בגין השיק רק במקרה של כשל תשלום עקב היעדר יכולת פירעון על ידי בעל השיק בלבד
        </li>
        <li>
            החברה אינה מתחייבת לפרוע את השיק במקרים אחרים, לרבות, ביטול ו/או הפרה של הסכם השכירות על ידי מי מהצדדים ו/או סכסוך ו/או מחלוקת שנתגלעה בין הצדדים
        </li>
        <li>
            בכל מקרה בו התגלע סכסוך ו/או מחלוקת בין בעל הנכס לבעל השיק, החברה לא תהיה מחויבת לפרוע את השיק, אלא על פי צו בית משפט ו/או החלטה שיפוטית אחרת, בהתאם לנסיבות
        </li>
        <li>
            הבטחת הפירעון מוגבלת לשיקים שנמסרו בעד דמי שכירות לתקופה שאינה עולה על 12 חודשים
        </li>
        <li>
            התחייבות החברה להעניק למשתמש זיכוי בגין שיק שחולל תחול בהתקיימות כל תנאי תקנון זה בלבד
        </li>
        <li>
            בכל מקרה של חילול שיק, מוקנית לחברה הזכות לפעול לגבייתו ולנקוט לשם כך בכל האמצעים העומדים לרשותה על פי דין, ובעל הנכס מוותר על כל טענה בהקשר זה
        </li>
    </ol>

    <p>
        מסירת פרטים על ידי המשתמש לחברה
    </p>
    <ol start="33">
        <li>
            המשתמש מאשר כי כל המידע המועבר על ידו לחברה הנו אמיתי, נכון ומדויק והוא מתחייב לנהוג בתום לב כלפי החברה בכל עת
        </li>
        <li>
            המשתמש מאשר כי בטרם העברת הפרטים והמידע לחברה קיבל את הסכמת בעל השיק לשימוש במידע אודותיו ושמירתו על ידי החברה וזאת במסגרת שירותי החברה
        </li>
        <li>
            המשתמש נותן את הסכמתו לכך שהחברה תעשה שימוש בפרטים שיימסרו על ידו באתר, לרבות, פניה ישירה לשוכר פוטנציאלי (בעל השיק)
        </li>
        <li>
            המשתמש ו/או בעל השיק מסכים כי מעבר לפרטים אותו ימסור לחברה, החברה תאסוף עליו מידע גלוי ומידע בתשלום, וזאת לצורך עריכת בדיקת ההיתכנות
        </li>
        <li>
            החברה תבצע אך ורק שימוש חוקי בפרטים שיתקבלו מן המשתמש בהתאם להוראות כל דין
        </li>
        <li>
            ידוע למשתמש כי האחריות לנכונות הפרטים המוזנים באתר תחול עליו באופן בלעדי ומוחלט.  תנאי להחלת התחייבות החברה לפירעון השיק הנו כי הפרטים שנמסרו על ידי המשתמש לחברה זהים לפרטים המופיעים בשיק. בכל מקרה של אי התאמה בין הפרטים שהוזנו באתר ו/או נמסרו על ידי המשתמש לחברה לבין הפרטים המופיעים בשיק תבוטל התחייבות החברה לפירעון ולמשתמש לא תהיינה כל טענות נגד החברה ו/או מי מטעמה בהקשר זה
        </li>
    </ol>

    <p>
        השימוש במידע שיימסר למשתמש על ידי החברה
    </p>
    <ol start="39">
        <li>
            המשתמש מתחייב לשמור על פרטיות בכל הקשור לבדיקת ההיתכנות ותוצאותיה, ולא לחשוף ו/או לגלות ו/או למסור, במישרין ו/או בעקיף, כל מידע אודות בעל השיק לכל גורם שהוא, בתמורה ו/או שלא בתמורה, ולנהוג על פי חוק הגנת הפרטיות, התשמ"א-1981
        </li>
        <li>
            השימוש המותר במידע הוא שימוש אישי ופרטי בלבד. המשתמש אינו רשאי לאפשר העברת המידע או להרשות שימוש כלשהו במידע, בין בתמורה ובין שלא בתמורה, לצדדים שלישיים
        </li>
        <li>
            מבלי לגרוע מכלליות האמור לעיל, המשתמש מתחייב, כי המידע לא ישמש אותו ו/או אחרים למטרות רווח ו/או למטרות מסחריות או עסקיות כלשהן ו/או באופן שעלול לפגוע בעסקי החברה, ובכלל זה הצגת ו/או אחסון המידע מחוץ לאתר ו/או האפליקציה ו/או בכל מדיה אחרת
        </li>
        <li>
            השימוש במידע ו/או הסתמכותו עליו, הנו באחריותו הבלעדית של המשתמש
        </li>
        <li>
            המשתמש מתחייב, להשתמש באתר בהתאם לדרישות כל דין, ובכפוף לתנאי השימוש כמפורט בתקנון זה, וכמו כן, להימנע מפגיעה בחברה ו/או בצדדים שלישיים
        </li>
        <li>
            המשתמש מצהיר בזאת כי ידוע לו שהחברה רשאית לנקוט כנגד משתמש שיפר הוראות תקנון זה בכל האמצעים העומדים לרשותה על פי דין, לרבות העברת פרטיו לצדדים שלישיים
        </li>
        <li>
            המשתמש מודע ומסכים לכך כי האתר לא נועד לספק מידע אינפורמטיבי, חדשותי או מידע כלכלי והוא אינו מחליף מקורות מידע רשמיים אשר עליהם חלות חובות מהסוג הזה
        </li>
    </ol>

    <p>
        אחריות מוגבלת
    </p>
    <ol start="46">
        <li>
            החברה אינה צד להתקשרות המשתמש עם בעל השיק ולפיכך אינה נושאת באחריות כלשהי בנוגע להתקשרות זו
        </li>
        <li>
            החברה פועלת במידת האפשר על מנת שהמידע על בסיסו נערכת בדיקת ההיתכנות יהיה נכון ומדויק ככל שניתן, אולם על אף האמור ייתכן ויחול ליקוי ו/או טעות ו/או אי דיוק במידע ו/או בדרך העברתו אל החברה ו/או אל משתמש ו/או בעל השיק. המשתמש ו/או בעל השיק פוטר בזאת את החברה, לרבות נציגיהם, עובדיהם, מנהליהם והפועלים מטעמם, מכל אחריות ו/או חבות ביחס לכך
        </li>
        <li>
            החברה, לרבות נציגיה, עובדיה, מנהליה והפועלים מטעמה, לא יהיו אחראים בגין כל נזק, ישיר ו/או עקיף, לרבות אובדן הכנסות ו/או אובדן רווחים, שייגרם למשתמש ו/או לבעל השיק ו/או לכל צד שלישי בגין השימוש באתר, בשירותים, במידע ו/או הסתמכות עליו ו/או לרבות בגין שיבושים ו/או ליקויים בהעברת המידע בין אם נגרמו במזיד על ידי צד שלישי כלשהו ובין אם נגרמו בשל תקלה כלשהי
        </li>
        <li>
            החברה אינה מתחייבת, כי השימוש באתר לא יופרע, יינתן כסדרו בלא הפסקות, יתקיים בבטחה ללא טעויות או יהיה חסין מפני נזקים, קלקולים, תקלות או כשלים, והמשתמש פוטר בזאת את החברה, לרבות נציגיה, עובדיה, מנהליה והפועלים מטעמה, מכל אחריות ו/או חבות הנוגעת לכך
        </li>
        <li>
            למשתמש ו/או לבעל השיק לא יהיו כל טענות, תלונות, דרישות או תביעות כלפי החברה ככל שיתגלו אי התאמות בין מידע שיימסר למשתמש למידע שמצוי מחוצה לא
        </li>
        <li>
            החברה אינה מתחייבת לזמינות רצופה. המשתמש מודע ומסכים לכך כי לא יזכה למענה מידי לפנייתו. החברה אף רשאית להפסיק את הפעילות ו/או השימוש באתר, ללא הודעה מוקדמת. המשתמש מודע ומסכים לכך כי לא יינתן כל פיצוי כספי בגין תקלות, שדרוג, הקפאת פעילות או סגירת האתר לאלתר
        </li>
        <li>
            המשתמש פוטר בזאת את החברה, לרבות נציגיה, עובדיה, מנהליה והפועלים מטעמה, מכל אחריות ו/או חבות הקשורה ו/או המתייחסת לרישום ו/או גביה ו/או סליקה אשר אינה מתבצעת ישירות על ידי החברה
        </li>
    </ol>

    <p>
        קנין רוחני
    </p>
    <ol start="53">
        <li>
            מלוא הזכויות באתר, בפרטים, במידע, בתכנים ובשירותים הקשורים לאתר שייכות לחברה, למעט תכנים או שירותים מאת צדדים שלישיים לגביהם יש לחברה זכויות שימוש. לפיכך, החברה הנה בעלת זכויות ההפעלה של האתר, ובעלת מלוא הזכויות בפעילות האתר, לרבות עיצובים גרפיים, פיתוחים, טקסטים, תמונות, צילומים, איורים, וידאו, סמלילים וסימני מסחר של האתר ו/או אשר מופיעים בה, מאגרי נתונים, מערכות ניהול ופיתוח האתר, תוכנות וכו'
        </li>
        <li>
            המשתמש מתחייב כי הוא לא יפרסם ברבים את המידע ו/או כל חלק ממנו לרבות כל מוצר ו/או פלט של המידע, אלא בתנאים כמפורט באתר, בין שהוא מודפס כשרטוט או כדו"ח ובין שהוא ניתן כקובץ על גבי אמצעים מגנטיים או בכל צורה אחרת. כן מתחייב המשתמש כי לא ישכפל, יצלם, יעתיק ו/או ידפיס כל מוצר ו/או פלט כאמור לעיל מתוך המידע או כל חלק ממנו לצורך הפצה או פרסום בכל דרך שהיא, וכן כי לא יסייע לאחרים לעשות כן
        </li>
        <li>
            מובהר כי אין להשתמש במידע ו/או בתכנים שבאתר לצורך יצירת ו/או פרסום לקט, אוסף, תקציר או מאגרי מידע וחדשות
        </li>
        <li>
            כל העתקה, הפצה, משלוח, העמדה לרשות הציבור, שידור או פרסום של מידע המאוחסן באפליקציה, שלא הותר במפורש, אסור, אלא אם ניתנה לכך הסכמת החברה מראש ובכתב
        </li>
    </ol>

    <p>
        דיוור
    </p>
    <ol start="57">
        <li>
            המשתמש מסכים ומאשר באופן מפורש כי הפרטים שיימסרו על ידו, לרבות פרטי התקשרות, ישמשו את החברה לצורך דיוור תכנים שונים באופן מסחרי אל המשתמש ו/או צדדים שלישיים, לרבות דיוור ישיר, ואף שיגור דברי פרסומת שונים, בהתאם לחוק התקשורת (בזק ושידורים), התשמ"ב-1982
        </li>
        <li>
            למשתמש תהא נתונה כל העת אפשרות להסיר עצמו מכל אחת מרשימות התפוצה של מסלולי הדיוור השונים, כמפורט על גבי כל הודעת דיוור כאמור, ולא תהא לו כל טענה כלפי החברה בקשר לקבלת דיוור כאמור. לאחר ההסרה, המידע יישמר במאגרי החברה אך לא ישמש לצורך דיוור תכנים באופן ישיר
        </li>
    </ol>

    <p>
        הדין החל ובוררות
    </p>
    <ol start="59">
        <li>
            על התקנון יחול הדין הישראלי. חילוקי דעות שיתגלעו בין המשתמש לחברה בקשר לתקנון זה ו/או לביצועו יתבררו בפני בורר אשר ימונה על ידי לשכת עורכי הדין בישראל
        </li>
        <li>
            מוסכם על הצדדים כי מקום מושבו של הבורר שימונה יהיה במחוז מרכז בלבד .על הבוררות יחולו הוראות חוק הבוררות, התשכ"ח-1968, התקנות שהותקנו מכוחו וכללי המוסד לבוררות של לשכת עורכי הדין בישראל. פסק הבוררות יהיה יינתן בדלתיים סגורות ויהא ניתן לערעור בפני בורר על פי הוראות סעיף 21 א' לחוק הבוררות, התשכ"ח-1968, והתוספות לחוק
        </li>
    </ol>

    <p>
        תנאים נוספים
    </p>
    <ol start="61">
        <li>
            בעצם השימוש מצהיר המשתמש כי תנאי השימוש ידועים לו ומוסכמים עליו וכי הוא מתחייב לפעול על פיהם
        </li>
        <li>
            המשתמש מתחייב לקרוא את התקנון מעת לעת ולפעול על פיו
        </li>
        <li>
            החברה שומרת על זכותה לשנות את תנאי השימוש באתר מעת לעת, בהתאם לשיקול דעתה הבלעדי. כל שינוי כאמור יחייב את המשתמש מרגע פרסומם באתר
        </li>
        <li>
            תנאי תקנון זה יחולו בנוסף לכל תנאי אחר בכתב כפי שייקבע בכתב בין המשתמש והחברה
        </li>
        <li>
            השירותים המוצעים על ידי החברה כרוכים בתשלום ועל המשתמש לפעול בהתאם להוראות החברה ותנאיה, ובכלל זה למסור פרטים נכונים ומדויקים, ולעדכן את החברה, ללא דיחוי, בכל מקרה של שינוי בפרטים שנמסרו לחברה. לידיעת המשתמש, מסירת פרטים אישיים כוזבים בהקשר זה, לרבות ביחס לאמצעי תשלום, מהווה עבירה פלילית כאמור בחוק העונשין, התשל"ז-1977, והינה אסורה מכל וכל
        </li>
        <li>
            ידוע למשתמש כי במקרה של הפרת תנאי השירות על ידו הרי שלחברה הזכות לחייבו בגין קנסות פיגורים ו/או לנקוט נגדו בהליכים משפטיים, לרבות הליכי הוצאה לפועל ללא כל הודעה מוקדמת, הכול על פי דין
        </li>
        <li>
            המשתמש מסכים כי אין לפרש את הניסוחים בתקנון זה באופן מצמצם כנגד החברה, וזאת בין היתר לנוכח השינויים התכופים בתחום האינטרנט והסתמכות החברה על תקנון זה. כל זכות שלא הוקנתה במפורש למשתמש שמורה לחברה
        </li>
        <li>
            אם ייקבע כי סעיף כלשהו בתקנון הינו נטול תוקף משפטי ו/או לא ניתן לאכיפה, יש לראות את הסעיף כאילו הוחלף בסעיף חוקי ואכיף, שתוכנו הינו הקרוב ביותר לכוונת הסעיף המקורי, וזאת ללא השפעה על תחולתן המחייבת והאכיפה של יתר סעיפי התקנון
        </li>
        <li>
            כל ויתור, דחיה, ארכה או הקלה מצד החברה כלפי המשתמש לא יהוו תקדים, לא יפעלו לחובת החברה ולא ייחשבו כוויתור על זכויותיה על פי התקנון, למעט במקרה של ויתור מפורש, המתייחס ספציפית, בכתב, להוראות התקנון
        </li>
        <li>
            האתר הינו אתר "מסחרי" והוא הוקם על מנת לעורר מודעות לשירות שאותו החברה מציעה
        </li>
        <li>
            ידוע למשתמש כי שירותי החברה ניתנים במסגרת פיקוח רשות שוק ההון, ביטחון וחיסכון במשרד האוצר
        </li>
        <li>
            תנאי השימוש מנוסחים בלשון זכר מטעמי נוחות בלבד והם מתייחסים לגברים ולנשים כאחד
        </li>
    </ol>

    <p>
        יצירת קשר
        בכל שאלה ו/או בקשה ניתן לפנות לחברה באמצעות:
        <a href={"tel:03-525-7777"}>03-525-7777</a>.
    </p>
</div>;

class Privacy extends Component {
    constructor (props) {
        super(props);
        this.state={
            showModal:this.props.show
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({showModal:nextProps.show});
    }

    clicked(){
        if (this.props.callback){
            this.props.callback();
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.show !== this.state.showModal
    }

    render () {
        return (
            <Modal show={this.state.showModal}
                   body={policy}
                   onClose={this.props.onClose.bind(this)}
                   buttons={[{name:'קראתי', callback:this.props.onClose.bind(this)}]}
            />
        )
    }
}
export default Privacy;
