import React, { Component } from "react";
import {bindActionCreators} from 'redux';
import * as app_actions from '../store/app/actions';
import "../css/ContactUs.css";
import {connect} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as ICONS from '@fortawesome/free-solid-svg-icons';
import {getContactUsForm} from "../config";
import Form from "../components/Form";
import rest from "../rest";

class ContactUs extends Component {
    constructor (props) {
        super(props);
        this.state = {
            form: getContactUsForm(),
        }

    }

    submit(fields, refs){
        // refs['message'].value = decodeURIComponent(refs['message'].value);
        let toS = {
            Op:"Rental.ContactUs",
            NAME:fields.name.value,
            PHONE:fields.phone.value,
            MSG:fields.message.value,
        }
        /*const data = `
        FullName=${fields.name.value}
        PhoneNumber=${fields.phone.value}
        Message=${fields.message.value}`;
        window.open("mailto:contact@pcsglobal.co.il?subject=צור קשר&body="+encodeURIComponent(data),"system")*/
        rest.contact_us(toS)
            .then((data)=>{
                this.setState({
                    form: getContactUsForm(),
                }, () => {
                    this.forceUpdate();
                });
                this.props.actions.updateModal({
                    body: "נשלח בהצלחה",
                    show: true
                })
            })
            .catch(()=>{

            })
    }

    render () {
        return (
            <React.Fragment>
            <div className={'contactUsPage flex-100 padd10'}>
                <div className={'flex-100 width100 paddBottom10px'}>
                    {/*<div className={"contactus-background flex-100 width100"}></div>*/}
                    <h3 className=" display-flex flex justify-content-center align-content-center width100 padd15px"
                    style={{
                        textDecoration: 'underline'
                    }}>
                        צור קשר
                        <FontAwesomeIcon icon={ICONS.faPhoneVolume} style={{
                            marginRight: 10,
                            width: '1.275em',
                            marginTop: 5,
                            height: '1.275em',
                        }}/>
                    </h3>
                </div>

                <div className="width100">
                <Form fields={this.state.form}
                      submitText={"שלח"}
                      hidePolicy={true}
                      callback={this.submit.bind(this)}/>
                </div>

                <div className="width100 text-right">
                    <a href="https://www.pcsglobal.co.il/%D7%A6%D7%95%D7%A8-%D7%A7%D7%A9%D7%A8.html" target="_blank">
                        <FontAwesomeIcon style={{marginLeft:5}} icon={ICONS.faGlobeAsia}/>
                        בקרו אותנו באתר
                    </a>
                </div>
            </div>
            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions; // [].concat.apply([],actions).filter(value => typeof value === 'function');

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);

