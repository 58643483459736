import React, { Component } from "react";
import {bindActionCreators} from 'redux';
import HomeCSS from '../css/home.css';
import * as app_actions from '../store/app/actions';
// import Form from '../components/Form'
import {statuses} from '../config';
import {Card,Button,OverlayTrigger,Popover,Tooltip} from 'react-bootstrap';
import uuid from 'uuid';
import {connect} from "react-redux";
import rest from '../rest';
import AddRenter from '../components/AddRenter';
import IPay from '../components/IPay';
import * as ActionButton from 'react-floating-action-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as ICONS from '@fortawesome/free-solid-svg-icons';
import FloatingMenu from '../components/FloatingMenu'

class Home extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showAddRenterModal:false,
            showIPayModal:false,
            renters:[]
        }

        this.props.actions.updateFooter({
            goBack:false,
            edit:true,
            aboutUs:true,
            phone: true
        })

    }

    getRenters(){
        let toS = {
            Op:"Rental.ResidentsList",
        }
        rest.get_renters(toS)
            .then((data)=>{
                this.setState({renters:data.residents || []}, this.checkForPay);
            })
            .catch((err)=>{

            })
    }

    checkForPay() {
        setTimeout(()=>{
            this.state.renters.forEach((resident)=>{
                if (resident.payamount){

                }
            })
        },3000)
    }

    componentWillMount() {
        this.getRenters.call(this);
    }
    componentDidMount() {
        this.getRentersInterval = setInterval(this.getRenters.bind(this),1000*10)
    }

    componentWillUnmount() {
        clearInterval(this.getRentersInterval);
    }

    requestInsurance (resident){
        let toS = {
            Op:"Rental.RequestInsurance",
            ResidentID:resident.residentid,
        }
        rest.request_insurance(toS)
            .then((data)=>{
                this.props.actions.updateModal({
                    body: data && data.message || "נשלחה בקשה לאבטחת פרעון",
                    show: true
                })
                this.getRenters();
            })
            .catch(()=>{

            })
    }

    deleteRenter(resident){
        let toS = {
            Op:"Rental.RemoveResident",
            ResidentID:resident.residentid,
        }
        rest.delete_renter(toS)
            .then((data)=>{
                this.getRenters();
            })
            .catch(()=>{

            })
    }

    renderTooltip(text) {
       return (
           <Tooltip id="tooltip-disabled">{text}</Tooltip>
        );
    }

    renderRenters() {
        const data = this.state.renters.filter(v => v.residentid).map((resident, index)=>{
            return (
                <Card key={`_${index}`} border={"gold"} className={`marginBottom20px flex-100 width100`}>
                    <Card.Body className={"text-right"}>
                        {/*<Card.Title>פרטים נוספים</Card.Title>*/}
                        <div className={"layout layout-row flex-100"}>
                            <div className="layout layout-column flex-60">
                                <Card.Text className={"layout layout-row"} style={{fontSize: '20px'}}>
                                    <span className={""}>
                                        {resident.name}
                                    </span>
                                </Card.Text>
                                <Card.Text className={"layout layout-row"}>
                                    <span className={"paddLeft10px"}>
                                        תעודת זהות:
                                    </span>
                                    <span className={""}>
                                        {resident.tehuda}
                                    </span>
                                </Card.Text>
                                <Card.Text className={"layout layout-row"}>
                                    <span className={"paddLeft10px"}>
                                        טלפון:
                                    </span>
                                    <span className={""}>
                                        <a href={`tel:${resident.phonenumber}`}>{resident.phonenumber}</a>
                                    </span>
                                </Card.Text>
                            </div>
                            <div className="layout layout-column flex-40">
                                {/*<Card.Text className={"layout layout-row"} style={{fontSize: '20px'}}>
                                    <span className={""}>&nbsp;</span>
                                </Card.Text>*/}
                                {<Card.Text className={"layout layout-row layout-align-center-center statusIconSize"}>
                                      <span>
                                          {statuses[resident.statuscode] && statuses[resident.statuscode].icon && <OverlayTrigger
                                          trigger="hover" placement="top" overlay={<Tooltip id={`tooltip${index}`}>{statuses[resident.statuscode].iconHover}</Tooltip>}>
                                              <FontAwesomeIcon size={'lg'} icon={statuses[resident.statuscode].icon}/>
                                            </OverlayTrigger>}
                                      </span>
                                </Card.Text>}
                                {<Card.Text className={"layout layout-row layout-align-center-center"}>
                                    <span className="text-center">
                                    {resident.statusdesc}
                                    </span>
                                    </Card.Text>}
                                {<Card.Text className="layout layout-column layout-align-center-center">
                                    {resident.confirmation && resident.confirmation !== "0" ? resident.confirmation :
                                    resident.approvecode && resident.approvecode !== "0" ? resident.approvecode : ''}
                                </Card.Text>}
                                <Card.Text className={'layout layout-row layout-align-center-center'}>
                                    <span>
                                    {resident.buttondesc && <React.Fragment>
                                        {!resident.payamount && <Button variant="primary" onClick={this.handleButton.bind(this,resident)}>{resident.buttondesc}</Button>}
                                        {resident.payamount && <Button variant="primary" onClick={this.goToIPay.bind(this,resident)}>{resident.buttondesc}</Button>}
                                    </React.Fragment>}
                                    </span>
                                </Card.Text>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )
        })
            .sort((a,b)=>{
                return a.residentid - b.residentid ? 1 : -1
            })
        return data;
    }

    handleButton(resident){
        const value = statuses[resident.statuscode];
        if (value.requestInsurance){
            this.requestInsurance(resident);
        } else if (value.enterDealInformation){
            this.goToPayment(resident);
        }
    }

    goToPayment(resident){
        let { history } = this.props;
        history.push({
            pathname: '/payment/'+resident.residentid,
            // property_renter_id: resident.residentid,
        });
    }

    goToIPay(resident){
        // let form =  `
        // <form method="POST" target="_blank" className="ipay_form" name="ipay_form" action="https://community.ipaygh.com/gateway">
        //         <input type="hidden" name="merchant_key" value="tk_c74ba68d-946a-ebc8-d908-e49dc61f89bc" />
        //         <input type="hidden" name="success_url" value="${resident.surl}" />
        //         <input type="hidden" name="cancelled_url" value="${resident.curl}" />
        //         <input type="hidden" name="deferred_url" value="" />
        //         <input type="hidden" name="currency" value="ILS" />
        //         <input type="hidden" name="invoice_id" value="${uuid().replace(/-/g,'').slice(0,25)}" />
        //         <input type="hidden" name="total" size="10" value="${resident.payamount}" />
        // </form>
        // `;
        // const base = resident.surl;
        // let surl = base.split('?')[0];
        // surl += "?qr=";
        // surl += base.split('?')[1].split('=')[1];
        // const json = JSON.parse(base.split('?')[1].split('=')[1]);
        // surl += "/" +json.op + ","  + json.key + "," + json.username + "," + json.ResidentID;
        // const tmp = `https://www.credix.co.il/mpi?userKey=${userKey}&uriSuccess=${encodeURIComponent(surl)}&editAmount=false&requireCurrency=false&Amount=${resident.payamount}&requireCardHolderId=true&requireCardHolderName=false&requireCVV=true&showFooter=true&maxNumberOfPayments=3&currency=NIS_CURRENCY&allowedCreditTerms=REGULAR_CREDIT,PAYMENTS`;
        /*rest.getPayURL({
            RedirectURL: resident.surl,
            FailRedirectURL: resident.furl,
            items: [{
                Quantity: 1,
                UnitPrice: resident.payamount,
                Description: resident.buttondesc,
                CatalogNumber: resident.residentid,
            }]
        })
            .then((res) => {
                console.log(res);
                // "PrivateSaleToken":
                // "PublicSaleToken":
                const payment = {
                    url: res.URL,
                    id: resident.residentid
                }
                this.openIPayModalModal(payment);
            })
            .catch((err) => {
                console.error(err);
            })
            */
             const payment = {
                    url: resident.paymenturl,
                    id: resident.residentid
                }
            this.openIPayModalModal(payment);
    }

    openAddRenterModal(){
        this.setState({showAddRenterModal:true});
    }

    openIPayModalModal(payment){
        this.residentIPay = payment
        this.setState({showIPayModal:true});
    }

    onRenterAdded(data){
        this.setState({showAddRenterModal:false});
        if (data) {
            this.getRenters();
            this.props.actions.updateModal({
                body: data && data.message || "נשלחה הודעת SMS לאימות",
                show: true
            })
        }
    }

    onIPayDone(triggered){
        this.setState({showIPayModal:false},()=>{
            this.residentIPay = null
        });
        if (triggered) {
            this.getRenters();
        }
    }

    render () {
        return (
            <div className={'rentersPage flex-100'}>
                <AddRenter show={this.state.showAddRenterModal}
                   onClose={this.onRenterAdded.bind(this)}
                />
                <IPay show={this.state.showIPayModal} resident={this.residentIPay}
                           onClose={this.onIPayDone.bind(this)}
                />
                <div className={'padd10 layout layout-column flex-100 layout-align-center-center'}>
                    {this.state.renters.length && <Card onClick={this.openAddRenterModal.bind(this)} className="padd10 layout-align-center-center text-center border-gold classA" style={{
                        borderRadius: '125px',
                        padding: '12px',
                        marginBottom: '15px',
                    }}>
                        <div style={{fontSize: '25px', cursor: 'pointer'}} className="classB">
                            לחץ כאן להוספת שוכרים
                            <FontAwesomeIcon className="classC" icon={ICONS.faPlus} style={{marginRight: 10, width: '0.575em', paddingTop: 4}}/>
                        </div>
                    </Card> || ''}
                    {this.state.renters.length && this.renderRenters() || ''}
                    {!this.state.renters.length && <Card onClick={this.openAddRenterModal.bind(this)} className="padd10 layout-align-center-center text-center border-gold" style={{
                        height: '250px',
                        width: '250px',
                        borderRadius: '125px',
                    }}>
                        <div style={{fontSize: '35px'}}>לחץ כאן להוספת שוכרים</div>
                    </Card> || ''}
                </div>


                {true && <ActionButton.Container className={`custom_fab-container`}>
                    <ActionButton.Link
                          tooltip="צור קשר"
                          href={'tel:03-525-7777'}
                          children={
                              <FontAwesomeIcon icon={ICONS.faPhone}/>
                              // <i className={'fa fa-phone'}></i>
                          }

                    />
                    <ActionButton.Link
                          tooltip="הוסף שוכר"
                          children={
                              <div onClick={this.openAddRenterModal.bind(this)}><FontAwesomeIcon icon={ICONS.faUserPlus}/></div>
                              // <i className={'fa fa-user-plus'} onClick={this.openAddRenterModal.bind(this)}></i>
                          }
                    />
                    <ActionButton.Button
                        className="fab-item btn btn-link btn-lg text-white"
                        tooltip=""
                        icon="fa fa-plus"
                        rotate={true}
                        />
                </ActionButton.Container>}

                {false && <FloatingMenu open={!this.state.renters.length} buttons={[{label:"צור קשר", icon:ICONS.faPhone, action:()=>{window.open('tel:03-525-7777','_system')}, key:"i1"},{label:"הוסף שוכר", icon:ICONS.faUserPlus, action:this.openAddRenterModal.bind(this), key:"i2"}]}/>}

                <div id={'hiddenPayment'}></div>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

function mapDispatchToProps(dispatch) {
    const creators = app_actions;

    return {
        actions: bindActionCreators(creators, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
